import { combineReducers } from 'redux';
import manageCustomer from '../../components/onboarding/reduxStore/reducer';
import AccountSetup from '../../components/account-setup/reduxStore/reducer';
import idVerificationInformation from '../../components/id-verification/reduxStore/reducer';
import WrapperComponents from '../../components/Wrappers/reduxStore/reducer';
import ReferralReducer from '../../components/refer-a-friend/redux/reducer';

const appReducer = combineReducers({
  customerInfo: manageCustomer,
  AccountSetupInfo: AccountSetup,
  idVerificationInformation: idVerificationInformation,
  WrapperComponents: WrapperComponents,
  newAccountSetup: manageCustomer,
  referralReducer: ReferralReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT' || action.type === 'RESET_REDUX_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
