export const ONBOARDING_API_V2 = 'onboarding-management/v2/users';
export const ONBOARDING_API = 'onboarding-management/v1/users';
const V1 = 'v1';
const V2 = 'v2';
export const ENDPOINTS = {
  SIGNUP_OTPS: `auth-management/${V1}/signup-otps`,
  V1_USERS: `auth-management/${V2}/users`,
  VALIDATE: `${V1}/passwordToken/validate`,
  V2_PASSWORD: `${V2}/password`,
  V1_CREDENTIALS: `${V1}/credentials`,
  V2_CREDENTIALS: `auth-management/${V2}/credentials`,
  V1_TOKEN: `${V1}/token`,
  V1_PROSPECTS: `onboarding-management/${V1}/prospects`,
  COMPANY_INFO: 'company-info',
  INDUSTRIES: `reference-data-management/${V1}/onboarding-forms/industries`,
  COUNTRIES: `public/${V1}/countries?filterRestrictedCountriesForOnboarding=false`,
  COUNTRIES_OF_INCORPORATION: `public/${V1}/countries`,
  NATIONALITIES: `public/${V1}/nationalities?filterRestrictedNationalitiesForOnboarding=false`,
  BUSINESS_INFO: 'business-info',
  USERS: `auth-management/${V2}/users`,
  STAKEHOLDER_INDIVIDUALS: 'stakeholder-individuals',
  STAKEHOLDER_COMPANIES: 'stakeholder-companies',
  THIRD_PARTIES: 'third-parties',
  INDIVIDUAL_STAKEHOLDER_DOCUMENTS: 'stakeholder-individual-documents',
  COMPANY_STAKEHOLDER_DOCUMENTS: 'stakeholder-company-documents',
  APPLICATION_SUBMISSION: '/submit',
  OTHER_JURISDICTION_COUNTRIES: `public/${V1}/other-countries-of-incorporation`,
  PAYMENT_COUNTRIES: `bank-account-management/${V1}/countries?payments-filter=true`,
  COUNTRY_DIAL_CODES: `public/${V1}/countries-dial-codes`,
  PHONE_VALIDATION_OTPS: `auth-management/${V1}/phone-validation-otps`,
  UPDATE_PHONE: `auth-management/${V1}/update-phone`,
  GENERATE_MFA_OTP: `auth-management/v1/mfa-process`,
  VERIFY_MFA_OTP: `auth-management/v1/login`,
  CREATE: 'company/create',
  UPDATE: 'company/update',
  DETAILS: 'company/details',
  UPLOAD_FILE: 'company/uploadFile',
  CREATE_PAYMENT: 'company/createPayment',
  DELETE_STAKHOLDER: 'company/deleteStakeholder'
};
export const HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/b88e67a2-d3e6-4c16-a6a0-d182653e8c5c'
};

export const RESOURCES_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/00874903-1a34-436f-b9ce-ad5def68956e'
};

export const CHECK_REFERRAL_CODE_HUBSPOT_INTEGRATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/91f394aa-5c05-4f30-b80a-f0745bedf2d6'
};

export const NEW_COMPANY_INCORPORATION = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/616c1ccd-5189-4381-82c6-fdc2f6fc6bf6'
};
