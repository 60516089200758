import * as actionTypes from './actionTypes';

let initialState = {
  verificationInformation: {},
  loading: false,
  error: undefined
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ID_VERIFICATION_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_ID_VERIFICATION_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_ID_VERIFICATION_SUCCESS:
      return { ...state, verificationInformation: { ...action.fetchVerificationInformation }, loading: false, error: undefined };
    default:
      return state;
  }
};
export default reducer;
