import * as actionTypes from './actionTypes';

let initialState = {
  checkReferralResponse: {},
  loading: false,
  error: false
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHECK_REFERRAL_CODE_REQUEST:
      return { ...state, loading: true };
    case actionTypes.CHECK_REFERRAL_CODE_FAILURE:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CHECK_REFERRAL_CODE_SUCCESS:
      return { ...state, checkReferralResponse: action.checkReferral, loading: false, error: false };
    default:
      return state;
  }
};
export default referralReducer;
