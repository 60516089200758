import * as actionTypes from "./actionTypes";

let initialState = {
    jurisdictionCountry: {},
    sentOtp: {},
    verifyOtp:{},
    customerUid:{},
    validateToken: {},
    setPassword: {},
    userLogin: {},
    userOnboarding: {},
    nationalitiesList: {},
    otherJurisdictionCountriesList: {},
    loading: false,
    error: false,
    newCompanyRegistrationData: {},
    currentStakeHolder: {},
    completedRegistration: [],
    allStakeHolder: [],
    stakeHolderForEdit: {},
    companyCreateDetails: {},
    companyUpdateDetails: {},
    allCompanyDetails: {},
    uploadPassportFile: {},
    paymentDetails: {},
    deletedStakHolderResponse: {},
    fetchClients: {},
    fetchPaymentCardApplications: {},
    mfaOtpResponse: {},
    verifyMfaOtpResponse: {},
    generateMfaNotificationResponse: {},
    validateSetupPasswordTokenResponse: {},
    updateUserPasswordResponse: {},
    resendPasswordSetupLinkResponse: {},
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.JURISDICTION_COUNTRY_REQUEST_SUCCESS:
            return { ...state, jurisdictionCountry: { ...action.jurisdictionCountry }, loading: false, error: false };

        case actionTypes.SENT_OTP_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.SENT_OTP_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.SENT_OTP_REQUEST_SUCCESS:
            return { ...state, sentOtp: { ...action.sentOtp }, loading: false, error: false };

        case actionTypes.VERIFY_OTP_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.VERIFY_OTP_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VERIFY_OTP_REQUEST_SUCCESS:
            return { ...state, verifyOtp: { ...action.verifyOtp }, loading: false, error: false };

        case actionTypes.CUSTOMER_UID_REQUEST_SUCCESS:
            return { ...state, customerUid: { ...action.customerUid }, loading: false, error: false };

        case actionTypes.VALIDATE_TOKEN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.VALIDATE_TOKEN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VALIDATE_TOKEN_REQUEST_SUCCESS:
            return { ...state, validateToken: { ...action.validateToken }, loading: false, error: false };

        case actionTypes.SET_PASSWORD_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.SET_PASSWORD_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.SET_PASSWORD_REQUEST_SUCCESS:
            return { ...state, setPassword: { ...action.setPassword }, loading: false, error: false };

        case actionTypes.USER_LOGIN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.USER_LOGIN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.USER_LOGIN_REQUEST_SUCCESS:
            return { ...state, userLogin: { ...action.userLogin }, loading: false, error: false };

        case actionTypes.GET_TOKEN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_TOKEN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_TOKEN_REQUEST_SUCCESS:
            return { ...state, getToken: { ...action.getToken }, loading: false, error: false };

        case actionTypes.USER_ONBOARDING_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.USER_ONBOARDING_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.USER_ONBOARDING_REQUEST_SUCCESS:
            return { ...state, userOnboarding: { ...action.userOnboarding }, loading: false, error: false };

        case actionTypes.NATIONALITIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.NATIONALITIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.NATIONALITIES_LIST_REQUEST_SUCCESS:
            return { ...state, nationalitiesList: { ...action.nationalitiesList }, loading: false, error: false };

        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS:
            return { ...state, otherJurisdictionCountriesList: { ...action.otherJurisdictionCountriesList }, loading: false, error: false };

        case actionTypes.NEW_COMPANY_REGISTRATION:
            return {
                ...state,
                newCompanyRegistrationData: { ...state.newCompanyRegistrationData, ...action.newCompanyRegistrationData }
            };
        case actionTypes.COMPLETED_COMPANY_REGISTRATION:
            return {
                ...state,
                completedRegistration: [...action.completedCompanyRegistrationData]
            };
        case actionTypes.ADD_ALL_STAKEHOLDER:
            return {
                ...state,
                allStakeHolder: [...action.stakeHolder]
            };
        case actionTypes.ADD_STAKEHOLDER_DATA:
            return {
                ...state,
                currentStakeHolder: { ...state.currentStakeHolder, ...action.currentStakeHolder }
            };
        case actionTypes.STAKEHOLDER_EDIT_DETAILS:
            return {
                ...state,
                stakeHolderForEdit: action.stakeHolderInfo
            };
        case actionTypes.STAKEHOLDER_DETAILS_REMOVE:
            return {
                ...state,
                stakeHolderForEdit: initialState.stakeHolderForEdit
            };

        case actionTypes.COMPANY_CREATE_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.COMPANY_CREATE_REQUEST_SUCCESS:
            return {
                ...state,
                companyCreateDetails: action.companyCreatePayload,
                loading: false,
                error: false
            };
        case actionTypes.COMPANY_CREATE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.COMPANY_UPDATE_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.COMPANY_UPDATE_REQUEST_SUCCESS:
            return {
                ...state,
                companyUpdateDetails: action.companyUpdatePayload,
                loading: false,
                error: false
            };
        case actionTypes.COMPANY_UPDATE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.COMPANY_DETAILS_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.COMPANY_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                allCompanyDetails: action.companyDetails,
                loading: false,
                error: false
            };
        case actionTypes.COMPANY_DETAILS_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.UPLOAD_FILE_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_FILE_REQUEST_SUCCESS:
            return {
                ...state,
                uploadPassportFile: action.fileData,
                loading: false,
                error: false
            };
        case actionTypes.UPLOAD_FILE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.PAYMENT_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                paymentDetails: action.paymentData,
                loading: false,
                error: false
            };
        case actionTypes.PAYMENT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.DELETE_STAKHOLDER_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_STAKHOLDER_SUCCESS:
            return {
                ...state,
                deletedStakHolderResponse: action.deletedData,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_STAKHOLDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actionTypes.FETCH_CLIENTS_START:
            return { ...state, loading: true };
        case actionTypes.FETCH_CLIENTS_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.FETCH_CLIENTS_SUCCESS:
            return { ...state, fetchClients: { ...action.fetchClients }, loading: false, error: false };

        case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_START:
            return { ...state, loading: true };
        case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS:
            return {
                ...state,
                fetchPaymentCardApplications: { ...action.fetchPaymentCardApplications },
                loading: false,
                error: false
            };

        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START:
            return { ...state, loading: true };
        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS:
            return {
                ...state,
                fetchProspectApplicationProcessStatusCompleted: { ...action.fetchProspectApplicationProcessStatusCompleted },
                loading: false,
                error: false
            };

        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START:
            return { ...state, loading: true };
        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS:
            return {
                ...state,
                fetchProspectApplicationProcessStatusIncomplete: { ...action.fetchProspectApplicationProcessStatusIncomplete },
                loading: false,
                error: false
            };

        case actionTypes.GENERATE_MFA_OTP_START:
            return { ...state, loading: true };
        case actionTypes.GENERATE_MFA_OTP_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GENERATE_MFA_OTP_SUCCESS:
            return { ...state, mfaOtpResponse: { ...action.mfaOtpResponse }, loading: false, error: false };
        case actionTypes.VERIFY_MFA_OTP_START:
            return { ...state, loading: true };
        case actionTypes.VERIFY_MFA_OTP_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VERIFY_MFA_OTP_SUCCESS:
            return { ...state, verifyMfaOtpResponse: { ...action.verifyMfaOtpResponse }, loading: false, error: false };

        case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_START:
            return { ...state, loading: true };
        case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state,
                verifyMfaNotificationStatusResponse: { ...action.verifyMfaNotificationStatusResponse },
                loading: false,
                error: false
            };

        case actionTypes.GENERATE_MFA_NOTIFICATION_START:
            return { ...state, loading: true };
        case actionTypes.GENERATE_MFA_NOTIFICATION_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GENERATE_MFA_NOTIFICATION_SUCCESS:
            return {
                ...state,
                generateMfaNotificationResponse: { ...action.generateMfaNotificationResponse },
                loading: false,
                error: false
            };

        case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_START:
            return { ...state, loading: true };
        case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                validateSetupPasswordTokenResponse: { ...action.validateSetupPasswordTokenResponse },
                loading: false,
                error: false
            };

        case actionTypes.UPDATE_USER_PASSWORD_START:
            return { ...state, loading: true };
        case actionTypes.UPDATE_USER_PASSWORD_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                updateUserPasswordResponse: { ...action.updateUserPasswordResponse },
                loading: false,
                error: false
            };

        case actionTypes.RESEND_PASSWORD_SETUP_LINK_START:
            return { ...state, loading: true };
        case actionTypes.RESEND_PASSWORD_SETUP_LINK_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.RESEND_PASSWORD_SETUP_LINK_SUCCESS:
            return {
                ...state,
                resendPasswordSetupLinkResponse: { ...action.resendPasswordSetupLinkResponse },
                loading: false,
                error: false
            };

        default: return state;

    }
}
export default reducer;
