import './src/styles/global.css';
import './src/styles/responsive.scss';
import './src/styles/variable.scss';
import './src/styles/global.scss';
import './src/styles/typography.scss';
import './src/styles/header-navigation.scss';
import './src/styles/buttons.scss';
import './src/styles/footer.scss';
import './src/styles/cards.scss';
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/store/store';
import { fetchAppVersion } from './src/helper/helpers';

fetchAppVersion();

// export { default as wrapRootElement } from './src/state/reduxWrapper';

export const wrapPageElement = ({ element }) => {

  return (
    <>
      <Provider store={store}>{element}</Provider>
    </>
  );
};

// export const wrapRootElement = ({element}) => (
//   <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
//     <Provider store={store}>{element}</Provider>
//   </PrismicPreviewProvider>
// )
