export const COMPANY_DATA_REQUEST_SUCCESS = 'COMPANY_DATA_REQUEST_SUCCESS';

export const BUSINESS_DATA_SELECTED_INDUSTRY_SUCCESS = 'BUSINESS_DATA_SELECTED_INDUSTRY_SUCCESS';

export const BUSINESS_DATA_RESET_SELECTED_INDUSTRY_SUCCESS = 'BUSINESS_DATA_RESET_SELECTED_INDUSTRY_SUCCESS';

export const COMPANY_INFORMATION_REQUEST_START = 'COMPANY_INFORMATION_REQUEST_START';
export const COMPANY_INFORMATION_REQUEST_FAIL = 'COMPANY_INFORMATION_REQUEST_FAIL';
export const COMPANY_INFORMATION_REQUEST_SUCCESS = 'COMPANY_INFORMATION_REQUEST_SUCCESS';

export const STEPS_STATUS_REQUEST_SUCCESS = 'STEPS_STATUS_REQUEST_SUCCESS';

export const INDUSTRIES_LIST_REQUEST_START = 'INDUSTRIES_LIST_REQUEST_START';
export const INDUSTRIES_LIST_REQUEST_FAIL = 'INDUSTRIES_LIST_REQUEST_FAIL';
export const INDUSTRIES_LIST_REQUEST_SUCCESS = 'INDUSTRIES_LIST_REQUEST_SUCCESS';

export const BUSINESS_DATA_REQUEST_SUCCESS = 'BUSINESS_DATA_REQUEST_SUCCESS';

export const COUNTRIES_LIST_REQUEST_START = 'COUNTRIES_LIST_REQUEST_START';
export const COUNTRIES_LIST_REQUEST_FAIL = 'COUNTRIES_LIST_REQUEST_FAIL';
export const COUNTRIES_LIST_REQUEST_SUCCESS = 'COUNTRIES_LIST_REQUEST_SUCCESS';

export const INCORPORATION_COUNTRIES_LIST_REQUEST_START = 'INCORPORATION_COUNTRIES_LIST_REQUEST_START';
export const INCORPORATION_COUNTRIES_LIST_REQUEST_FAIL = 'INCORPORATION_COUNTRIES_LIST_REQUEST_FAIL';
export const INCORPORATION_COUNTRIES_LIST_REQUEST_SUCCESS = 'INCORPORATION_COUNTRIES_LIST_REQUEST_SUCCESS';

export const NATIONALITIES_LIST_REQUEST_START = 'NATIONALITIES_LIST_REQUEST_START';
export const NATIONALITIES_LIST_REQUEST_FAIL = 'NATIONALITIES_LIST_REQUEST_FAIL';
export const NATIONALITIES_LIST_REQUEST_SUCCESS = 'NATIONALITIES_LIST_REQUEST_SUCCESS';

export const BUSINESS_INFORMATION_REQUEST_START = 'BUSINESS_INFORMATION_REQUEST_START';
export const BUSINESS_INFORMATION_REQUEST_FAIL = 'BUSINESS_INFORMATION_REQUEST_FAIL';
export const BUSINESS_INFORMATION_REQUEST_SUCCESS = 'BUSINESS_INFORMATION_REQUEST_SUCCESS';

export const USER_INFORMATION_REQUEST_START = 'USER_INFORMATION_REQUEST_START';
export const USER_INFORMATION_REQUEST_FAIL = 'USER_INFORMATION_REQUEST_FAIL';
export const USER_INFORMATION_REQUEST_SUCCESS = 'USER_INFORMATION_REQUEST_SUCCESS';

export const CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START = 'CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START';
export const CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL = 'CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL';
export const CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS = 'CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS';

export const GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_START = 'GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_START';
export const GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL = 'GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL';
export const GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS = 'GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS';

export const GET_ALL_COMPANY_STAKEHOLDER_REQUEST_START = 'GET_ALL_COMPANY_STAKEHOLDER_REQUEST_START';
export const GET_ALL_COMPANY_STAKEHOLDER_REQUEST_FAIL = 'GET_ALL_COMPANY_STAKEHOLDER_REQUEST_FAIL';
export const GET_ALL_COMPANY_STAKEHOLDER_REQUEST_SUCCESS = 'GET_ALL_COMPANY_STAKEHOLDER_REQUEST_SUCCESS';

export const OWNERS_INDIVIDUAL_DATA_REQUEST_SUCCESS = 'OWNERS_INDIVIDUAL_DATA_REQUEST_SUCCESS';

export const OWNERS_COMPANY_DATA_REQUEST_SUCCESS = 'OWNERS_COMPANY_DATA_REQUEST_SUCCESS';

export const CREATE_COMPANY_STAKEHOLDER_REQUEST_START = 'CREATE_COMPANY_STAKEHOLDER_REQUEST_START';
export const CREATE_COMPANY_STAKEHOLDER_REQUEST_FAIL = 'CREATE_COMPANY_STAKEHOLDER_REQUEST_FAIL';
export const CREATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS = 'CREATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS';

export const CREATE_THIRD_PARTY_REQUEST_START = 'CREATE_THIRD_PARTY_REQUEST_START';
export const CREATE_THIRD_PARTY_REQUEST_FAIL = 'CREATE_THIRD_PARTY_REQUEST_FAIL';
export const CREATE_THIRD_PARTY_REQUEST_SUCCESS = 'CREATE_THIRD_PARTY_REQUEST_SUCCESS';

export const GET_ALL_THIRD_PARTY_REQUEST_START = 'GET_ALL_THIRD_PARTY_REQUEST_START';
export const GET_ALL_THIRD_PARTY_REQUEST_FAIL = 'GET_ALL_THIRD_PARTY_REQUEST_FAIL';
export const GET_ALL_THIRD_PARTY_REQUEST_SUCCESS = 'GET_ALL_THIRD_PARTY_REQUEST_SUCCESS';

export const DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_START = 'DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_START';
export const DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_FAIL = 'DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_FAIL';
export const DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_SUCCESS = 'DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_SUCCESS';

export const DELETE_STAKEHOLDER_COMPANY_REQUEST_START = 'DELETE_STAKEHOLDER_COMPANY_REQUEST_START';
export const DELETE_STAKEHOLDER_COMPANY_REQUEST_FAIL = 'DELETE_STAKEHOLDER_COMPANY_REQUEST_FAIL';
export const DELETE_STAKEHOLDER_COMPANY_REQUEST_SUCCESS = 'DELETE_STAKEHOLDER_COMPANY_REQUEST_SUCCESS';

export const DELETE_THIRD_PARTY_INFO_REQUEST_START = 'DELETE_THIRD_PARTY_INFO_REQUEST_START';
export const DELETE_THIRD_PARTY_INFO_REQUEST_FAIL = 'DELETE_THIRD_PARTY_INFO_REQUEST_FAIL';
export const DELETE_THIRD_PARTY_INFO_REQUEST_SUCCESS = 'DELETE_THIRD_PARTY_INFO_REQUEST_SUCCESS';

export const GET_INDIVIDUAL_STAKEHOLDER_REQUEST_START = 'GET_INDIVIDUAL_STAKEHOLDER_REQUEST_START';
export const GET_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL = 'GET_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL';
export const GET_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS = 'GET_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS';

export const UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START = 'UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START';
export const UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL = 'UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL';
export const UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS = 'UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS';

export const GET_COMPANY_STAKEHOLDER_REQUEST_START = 'GET_COMPANY_STAKEHOLDER_REQUEST_START';
export const GET_COMPANY_STAKEHOLDER_REQUEST_FAIL = 'GET_COMPANY_STAKEHOLDER_REQUEST_FAIL';
export const GET_COMPANY_STAKEHOLDER_REQUEST_SUCCESS = 'GET_COMPANY_STAKEHOLDER_REQUEST_SUCCESS';

export const UPDATE_COMPANY_STAKEHOLDER_REQUEST_START = 'UPDATE_COMPANY_STAKEHOLDER_REQUEST_START';
export const UPDATE_COMPANY_STAKEHOLDER_REQUEST_FAIL = 'UPDATE_COMPANY_STAKEHOLDER_REQUEST_FAIL';
export const UPDATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS = 'UPDATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS';

export const UPDATE_THIRD_PARTY_REQUEST_START = 'UPDATE_THIRD_PARTY_REQUEST_START';
export const UPDATE_THIRD_PARTY_REQUEST_FAIL = 'UPDATE_THIRD_PARTY_REQUEST_FAIL';
export const UPDATE_THIRD_PARTY_REQUEST_SUCCESS = 'UPDATE_THIRD_PARTY_REQUEST_SUCCESS';

export const GET_COMPANY_INFORMATION_REQUEST_START = 'GET_COMPANY_INFORMATION_REQUEST_START';
export const GET_COMPANY_INFORMATION_REQUEST_FAIL = 'GET_COMPANY_INFORMATION_REQUEST_FAIL';
export const GET_COMPANY_INFORMATION_REQUEST_SUCCESS = 'GET_COMPANY_INFORMATION_REQUEST_SUCCESS';

export const UPLOAD_DOCUMENTS_REQUEST_START = 'UPLOAD_DOCUMENTS_REQUEST_START';
export const UPLOAD_DOCUMENTS_REQUEST_FAIL = 'UPLOAD_DOCUMENTS_REQUEST_FAIL';
export const UPLOAD_DOCUMENTS_REQUEST_SUCCESS = 'UPLOAD_DOCUMENTS_REQUEST_SUCCESS';

export const CREATE_COMPANY_DOCUMENTS_REQUEST_START = 'CREATE_COMPANY_DOCUMENTS_REQUEST_START';
export const CREATE_COMPANY_DOCUMENTS_REQUEST_FAIL = 'CREATE_COMPANY_DOCUMENTS_REQUEST_FAIL';
export const CREATE_COMPANY_DOCUMENTS_REQUEST_SUCCESS = 'CREATE_COMPANY_DOCUMENTS_REQUEST_SUCCESS';

export const CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_START = 'CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_START';
export const CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL = 'CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL';
export const CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS = 'CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS';

export const GET_BUSINESS_INFORMATION_REQUEST_START = 'GET_BUSINESS_INFORMATION_REQUEST_START';
export const GET_BUSINESS_INFORMATION_REQUEST_FAIL = 'GET_BUSINESS_INFORMATION_REQUEST_FAIL';
export const GET_BUSINESS_INFORMATION_REQUEST_SUCCESS = 'GET_BUSINESS_INFORMATION_REQUEST_SUCCESS';

export const GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_START = 'GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENT_REQUEST_START';
export const GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL = 'GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENT_REQUEST_FAIL';
export const GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS = 'GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENT_REQUEST_SUCCESS';

export const GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_START = 'GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_START';
export const GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL = 'GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL';
export const GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS = 'GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS';

export const GET_COMPANY_DOCUMENTS_REQUEST_START = 'GET_COMPANY_DOCUMENTS_REQUEST_START';
export const GET_COMPANY_DOCUMENTS_REQUEST_FAIL = 'GET_COMPANY_DOCUMENTS_REQUEST_FAIL';
export const GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS = 'GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS';

export const COMPLETE_STAGE_REQUEST_START = 'COMPLETE_STAGE_REQUEST_START';
export const COMPLETE_STAGE_REQUEST_FAIL = 'COMPLETE_STAGE_REQUEST_FAIL';
export const COMPLETE_STAGE_REQUEST_SUCCESS = 'COMPLETE_STAGE_REQUEST_SUCCESS';

export const APPLICATION_SUBMISSION_REQUEST_START = 'APPLICATION_SUBMISSION_REQUEST_START';
export const APPLICATION_SUBMISSION_REQUEST_FAIL = 'APPLICATION_SUBMISSION_REQUEST_FAIL';
export const APPLICATION_SUBMISSION_REQUEST_SUCCESS = 'APPLICATION_SUBMISSION_REQUEST_SUCCESS';

export const APPLICATION_METADATA_SUBMISSION_REQUEST_SUCCESS = 'APPLICATION_METADATA_SUBMISSION_REQUEST_SUCCESS';

export const PAYMENT_COUNTRIES_LIST_REQUEST_START = 'PAYMENT_COUNTRIES_LIST_REQUEST_START';
export const PAYMENT_COUNTRIES_LIST_REQUEST_FAIL = 'PAYMENT_COUNTRIES_LIST_REQUEST_FAIL';
export const PAYMENT_COUNTRIES_LIST_REQUEST_SUCCESS = 'PAYMENT_COUNTRIES_LIST_REQUEST_SUCCESS';

export const COUNTRIES_DIAL_CODES_REQUEST_START = 'COUNTRIES_DIAL_CODES_REQUEST_START';
export const COUNTRIES_DIAL_CODES_REQUEST_FAIL = 'COUNTRIES_DIAL_CODES_REQUEST_FAIL';
export const COUNTRIES_DIAL_CODES_REQUEST_SUCCESS = 'COUNTRIES_DIAL_CODES_REQUEST_SUCCESS';

export const PHONE_VALIDATION_OTPS_REQUEST_START = 'PHONE_VALIDATION_OTPS_REQUEST_START';
export const PHONE_VALIDATION_OTPS_REQUEST_FAIL = 'PHONE_VALIDATION_OTPS_REQUEST_FAIL';
export const PHONE_VALIDATION_OTPS_REQUEST_SUCCESS = 'PHONE_VALIDATION_OTPS_REQUEST_SUCCESS';

export const UPADTE_PHONE_REQUEST_START = 'UPADTE_PHONE_REQUEST_START';
export const UPADTE_PHONE_REQUEST_FAIL = 'UPADTE_PHONE_REQUEST_FAIL';
export const UPADTE_PHONE_REQUEST_SUCCESS = 'UPADTE_PHONE_REQUEST_SUCCESS';
